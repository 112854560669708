// src/components/Auth.js
import React, { useState } from "react";
import { initializeApp } from "firebase/app";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, updateProfile } from "firebase/auth";
import { getFirestore, collection, getDocs, query, where, addDoc } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyC2AqVJGdH1I2ainxhhOpkK3n-HP1BVF9Y",
    authDomain: "carbon-chat.firebaseapp.com",
    projectId: "carbon-chat",
    storageBucket: "carbon-chat.appspot.com",
    messagingSenderId: "23998182324",
    appId: "1:23998182324:web:b69d439fa34754def038e1",
    measurementId: "G-3DLDQN3PFV"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const firestore = getFirestore(app);
const usersCollection = collection(firestore, "users");

const Auth = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [username, setUsername] = useState("");

    const handleSignUp = async () => {
        try {
            const querySnapshot = await getDocs(query(usersCollection, where("displayName", "==", username)));
            if (!querySnapshot.empty) {
                document.getElementById('error_modal_error').textContent = "Username already taken. Please choose another one.";
                document.getElementById('error_modal').showModal();
            } else {
                const userCredential = await createUserWithEmailAndPassword(auth, email, password);

                await updateProfile(userCredential.user, { displayName: username });

                await addDoc(usersCollection, {
                    uid: userCredential.user.uid,
                    email: userCredential.user.email,
                    displayName: username
                });
            }
        } catch (error) {
            console.error("Error signing up: ", error);
            let errorText;
            console.log(error.code)
            switch (error.code) {
                case "auth/email-already-in-use":
                    errorText = "Email is already in use.";
                    break;
                case "auth/invalid-email":
                    errorText = "Invalid email.";
                    break;
                case "auth/operation-not-allowed":
                    errorText = "Operation not allowed.";
                    break;
                case "auth/weak-password":
                    errorText = "Password is too weak.";
                    break;
                case "auth/missing-password":
                    errorText = "Missing password.";
                    break;
                default:
                    errorText = "Unknown error during sign up.";
            }
            document.getElementById('error_modal_error').textContent = errorText;
            document.getElementById('error_modal').showModal();
        }
    };

    const handleSignIn = async () => {
        try {
            await signInWithEmailAndPassword(auth, email, password);
        } catch (error) {
            console.error("Error signing in: ", error);
            let errorText;
            switch (error.code) {
                case "auth/invalid-email":
                    errorText = "Invalid email.";
                    break;
                case "auth/invalid-credential":
                    errorText = "Incorrect email and/or password.";
                    break;
                case "auth/network-request-failed":
                    errorText = "Network error. Please check your connection.";
                    break;
                case "auth/missing-password":
                    errorText = "Missing password.";
                    break;
                default:
                    errorText = "Unknown error during sign in.";
            }
            document.getElementById('error_modal_error').textContent = errorText;
            document.getElementById('error_modal').showModal();
        }
    };

    return (
        <div className="container mx-auto m-12 w-4/5">
            <div className="text-3xl">Welcome to Carbon!</div>
            <div className="text-base text-warning mt-4">The username box is only needed to sign up. It does nothing when signing in.</div>
            <label className="input input-bordered flex items-center gap-2 mt-4 mb-4">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                    fill="currentColor"
                    className="h-4 w-4 opacity-70">
                    <path
                        d="M2.5 3A1.5 1.5 0 0 0 1 4.5v.793c.026.009.051.02.076.032L7.674 8.51c.206.1.446.1.652 0l6.598-3.185A.755.755 0 0 1 15 5.293V4.5A1.5 1.5 0 0 0 13.5 3h-11Z" />
                    <path
                        d="M15 6.954 8.978 9.86a2.25 2.25 0 0 1-1.956 0L1 6.954V11.5A1.5 1.5 0 0 0 2.5 13h11a1.5 1.5 0 0 0 1.5-1.5V6.954Z" />
                </svg>
                <input
                    type="text"
                    className="grow"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                />
            </label>
            <label className="input input-bordered flex items-center gap-2 mt-4 mb-4">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                    fill="currentColor"
                    className="h-4 w-4 opacity-70">
                    <path
                        d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM12.735 14c.618 0 1.093-.561.872-1.139a6.002 6.002 0 0 0-11.215 0c-.22.578.254 1.139.872 1.139h9.47Z" />
                </svg>
                <input
                    type="text"
                    className="grow"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder="Username"
                />
            </label>
            <label className="input input-bordered flex items-center gap-2 mt-4 mb-4">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                    fill="currentColor"
                    className="h-4 w-4 opacity-70">
                    <path
                        fillRule="evenodd"
                        d="M14 6a4 4 0 0 1-4.899 3.899l-1.955 1.955a.5.5 0 0 1-.353.146H5v1.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-2.293a.5.5 0 0 1 .146-.353l3.955-3.955A4 4 0 1 1 14 6Zm-4-2a.75.75 0 0 0 0 1.5.5.5 0 0 1 .5.5.75.75 0 0 0 1.5 0 2 2 0 0 0-2-2Z"
                        clipRule="evenodd" />
                </svg>
                <input
                    type="password"
                    className="grow"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                />
            </label>
            <button onClick={handleSignUp} className="btn btn-primary btn-outline mr-4">Sign Up</button>
            <button onClick={handleSignIn} className="btn btn-primary btn-outline mr-4">Sign In</button>
            {/* <span className="mr-4"><span className="opacity-50">(Optional)</span> Upload a profile picture for your account:</span>
            <input
                type="file"
                className="file-input file-input-bordered file-input-primary w-full max-w-xs" />
            <br /><br /> */}
            <p className="m-4 text-xl">Changelog:</p>
            <p className="m-4 opacity-75">- changed default theme</p>
            <p className="m-4 opacity-75">- made desktop sidebar thinner</p>
            <p className="m-4 opacity-75">- compacted messages</p>
            <dialog id="error_modal" className="modal modal-bottom sm:modal-middle">
                <div className="modal-box">
                    <h3 className="text-lg font-bold">Error!</h3>
                    <p id="error_modal_error" className="py-4">Unknown error...</p>
                    <div className="modal-action">
                        <form method="dialog">
                            <button className="btn">Close</button>
                        </form>
                    </div>
                </div>
            </dialog>
        </div>
    );
};

export { app, auth };
export default Auth;