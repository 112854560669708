import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { collection, addDoc, serverTimestamp, query, orderBy, onSnapshot, getFirestore } from "firebase/firestore";
import { app, auth } from "../components/Auth";
import { signOut } from "firebase/auth";
import 'daisyui/dist/full.css';
import './md.css';
import { themeChange } from 'theme-change';
import { marked } from 'marked';

const firestore = getFirestore(app);

const themes = [
    "light", "dark", "cupcake", "bumblebee", "emerald", "corporate", "synthwave", "retro", "cyberpunk", 
    "valentine", "halloween", "garden", "forest", "aqua", "lofi", "pastel", "fantasy", "wireframe", "black", 
    "luxury", "dracula", "cmyk", "autumn", "business", "acid", "lemonade", "night", "coffee", "winter", "dim", 
    "nord", "sunset"
];

const PublicChat = () => {
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState("");
    const chatContainerRef = useRef(null);
    const [isScrolledToBottom, setIsScrolledToBottom] = useState(true);

    useEffect(() => {
        themeChange(false);
        const q = query(collection(firestore, "publicChat"), orderBy("createdAt"));
        const unsubscribe = onSnapshot(q, (snapshot) => {
            setMessages(snapshot.docs.map(doc => doc.data()));
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const chatContainer = chatContainerRef.current;
        const handleScroll = () => {
            const atBottom = chatContainer.scrollHeight - chatContainer.scrollTop <= chatContainer.clientHeight + 10;
            setIsScrolledToBottom(atBottom);
        };

        chatContainer.addEventListener("scroll", handleScroll);
        return () => chatContainer.removeEventListener("scroll", handleScroll);
    }, []);

    useEffect(() => {
        if (isScrolledToBottom) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [messages, isScrolledToBottom]);

    const handleSend = async (e) => {
        e.preventDefault();

        if (newMessage.trim() !== "") {
            await addDoc(collection(firestore, "publicChat"), {
                senderName: auth.currentUser.displayName,
                senderID: auth.currentUser.uid,
                text: newMessage,
                createdAt: serverTimestamp(),
                isMarkdown: true // Add this field to specify if the message is in Markdown
            });

            setNewMessage("");
        }
    };

    const handleSignOut = async (e) => {
        e.preventDefault();

        await signOut(auth);
    };

    return (
        <div className="drawer lg:drawer-open">
            <input id="my-drawer-2" type="checkbox" className="drawer-toggle" />
            <div className="drawer-content flex flex-col h-screen">
                <div className="flex justify-between items-center bg-base-100 p-4">
                    <label htmlFor="my-drawer-2" className="btn btn-ghost drawer-button lg:hidden">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            className="w-6 h-6"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M4 6h16M4 12h16m-7 6h7"
                            />
                        </svg>
                    </label>
                    <p className="text-3xl text-center flex-1">Public Chat</p>
                </div>
                <div ref={chatContainerRef} className="flex-1 overflow-y-auto p-4">
                    {messages.map((msg, index) => (
                        <div className="chat chat-start mb-1.5 break-words" key={index}>
                            <div className="chat-header">{msg.senderName}</div>
                            <div
                                className="chat-bubble bg-base-300 break-words max-w-7xl text-base-content"
                                dangerouslySetInnerHTML={{ __html: marked(msg.text) }}
                            />
                        </div>
                    ))}
                </div>
                <div className="bg-base-100 p-4 mt-auto">
                    <form onSubmit={handleSend} className="flex">
                        <input
                            value={newMessage}
                            onChange={(e) => setNewMessage(e.target.value)}
                            placeholder="Type a message"
                            className="input input-bordered input-primary flex-1 mr-4"
                        />
                        <button type="submit" className="btn btn-primary">Send</button>
                    </form>
                </div>
            </div>
            <div className="drawer-side">
                <label htmlFor="my-drawer-2" aria-label="close sidebar" className="drawer-overlay"></label>
                <div className="bg-base-200 min-h-full w-64 p-4 flex flex-col justify-between">
                    <div>
                        <p className="text-2xl font-bold mb-4">Carbon <span className="opacity-25">v1.1</span></p>
                        <Link to="/public-chat">
                            <button className="btn btn-primary btn-outline mb-4 w-full">Public Chat</button>
                        </Link>
                        <Link to="/dms">
                            <button className="btn btn-primary btn-outline mb-4 w-full">DMs</button>
                        </Link>
                        <div className="dropdown">
                            <div tabIndex="0" role="button" className="btn btn-accent btn-outline">Select Theme</div>
                            <ul tabIndex="0" className="dropdown-content menu bg-base-100 rounded-box z-[1] w-52 p-2 shadow max-h-96 overflow-y-scroll">
                                <li data-set-theme="forest"><button>Default</button></li>
                                {themes.map(theme => (
                                    <li key={theme} data-set-theme={theme}>
                                        <button>{theme.charAt(0).toUpperCase() + theme.slice(1)}</button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <button className="btn btn-secondary btn-outline" onClick={handleSignOut}>Sign Out</button>
                </div>
            </div>
        </div>
    );
};

export default PublicChat;
