// src/App.js
import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Auth, { app, auth } from "./components/Auth"; // Importing app and auth from Auth component
import PublicChat from "./components/PublicChat";
import DMs from "./components/DMs";
import NotFoundErrorPage from "./components/errors/404";
import 'daisyui/dist/full.css';

const App = () => {
    const [user, setUser] = React.useState(null);

    React.useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            setUser(user);
        });

        return () => unsubscribe();
    }, []);

    return (
        <div>
            {user ? (
                <Routes>
                    <Route path="/public-chat" element={<PublicChat app={app} auth={auth} />} />
                    <Route path="/dms" element={<DMs app={app} auth={auth} />} />
                    <Route path="/404" element={<NotFoundErrorPage />} />
                    <Route path="/auth" element={<Navigate to="/public-chat" />} />
                    <Route path="/" element={<Navigate to="/public-chat" />} />
                    <Route path="/*" element={<Navigate to="/404" />} />
                </Routes>
            ) : (
                <Routes>
                    <Route path="/auth" element={<Auth />} />
                    <Route path="/404" element={<NotFoundErrorPage />} />
                    <Route path="/public-chat" element={<Navigate to="/auth" />} />
                    <Route path="/dms" element={<Navigate to="/auth" />} />
                    <Route path="/" element={<Navigate to="/auth" />} />
                    <Route path="/*" element={<Navigate to="/404" />} />
                </Routes>
            )}
        </div>
    );
};

export default App;
