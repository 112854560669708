// src/components/errors/404.js
import React from "react";
import { Link } from "react-router-dom";
import 'daisyui/dist/full.css';

const NotFoundErrorPage = () => {
    const splashes = [
        "looks like you're lost :3",
        "how did we get here?",
        "are you running a fever?"
    ];

    const splash = splashes[Math.floor(Math.random() * splashes.length)];

    return (
        <div className="flex flex-col items-center justify-center h-screen">
            <div className="text-center">
                <div className="text-5xl font-bold">Error! <span className="opacity-50">(404)</span></div>
                <div className="text-lg mt-4">Page not found -- {splash}</div>
                <Link to="/public-chat">
                    <button className="btn btn-primary btn-outline mt-4">Go to Public Chat</button>
                </Link>
            </div>
        </div>
    );
};

export default NotFoundErrorPage;
